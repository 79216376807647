import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import VirtualizedSelect from 'react-virtualized-select';
import createFilterOptions from '../filter';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import {neighbourhoodCodes} from '../neighbourhoodCodes'
import { Jumbotron, Button, Toast, ToastBody, ToastHeader, Card, CardTitle, CardText, Row, Col } from 'reactstrap';
import { withRouter, NavLink } from 'react-router-dom';
import axios from 'axios';

const options = neighbourhoodCodes;
const text2 = 'You can search by: A neighborhood code which you may select from the dropdown.';

const filterOptions = createFilterOptions({options});
// console.log({neighbourhoodCodes});
// console.log({options});

class Home extends Component {
   constructor(props,context) {

    super(props,context);
    this.state = { 
     neighbourhoodCode:null,
     show: false,
     suggestedSearchOptions: [],
     searchOptions: [],
     searchItem: '',
     searchClick: '',
     toResults: false,
    }
     this.toggle = this.toggle.bind(this);
  }

 

  componentDidMount() {
  const { match: { params } } = this.props;
}
handleInputChange = () => {
   this.setState({
     searchItem: this.search.value
   }, () => {
       if (this.state.searchItem && this.state.searchItem.length > 1)
       {
        this.getSuggestedSearchItems()
       }
   })
 }
handleClick = (item) => {
   this.setState({
     searchClick: item
   }, () => {
       if (this.state.searchClick && this.state.searchClick.length > 1)
       {
        this.getSearchItems()
       }
   })
 }

getSuggestedSearchItems() {
     delete axios.defaults.headers.common["X-Requested-With"];
    // Where we're fetching data from
    axios.get('https://property.spatialest.com/nc/mecklenburg/api/search/suggestions/'+ this.state.searchItem + '/5')
      // We get the API response and receive data in JSON format...
     .then( response => {
       this.setState({suggestedSearchOptions: response.data.suggestions})
      }).catch(error => {
    console.log('error', error);
        })
    }

   getSearchItems() {
    // How do we search with a slash in the search result
    // Where we're fetching data from
    axios.get('https://property.spatialest.com/nc/mecklenburg/api/search/' + this.state.searchClick + '/1/10')
      // We get the API response and receive data in JSON format...
     .then( response => {
       this.setState({toResults: true})
       this.goToResults();
      }).catch(error => {
    console.log('error', error);
})

  }

goToResults(){
  if (this.state.toResults === true) {
      this.props.history.push('/view/' + this.state.neighbourhoodCode);
    }
}
 toggle() {
    this.setState({
      show: !this.state.show
    });
  }

  // Keep this code for future development (Mecklenburg API)
  // <div className="col-lg-4 d-flex align-items-center form-group">
  //                   <input type="text" name="search" id="search" placeholder="What are you searching for?"  ref={input => this.search = input}
  //         onChange={this.handleInputChange} className="form-control border-0 shadow-0"/>
  //  </div> 
              
  render(){
  const { neighbourhoodCode } = this.state;
  return (
    <CSSTransitionGroup
      transitionName="homeTransition"
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnter={false}
      transitionLeave={false}>
      <div className="big-banner">
        <div className="container py-6 py-md-5 text-black z-index-20">
        <div className="row">
          <div className="col-xl-10">
            <div className="text-center text-lg-left">
              <p className="subtitle letter-spacing-4 mb-2 text-white text-shadow">Mecklenburg</p>
              <h1 className="display-3 font-weight-bold text-white text-shadow">Neighborhood Review</h1>
            </div>
            <div className="search-bar mt-5 p-6 p-lg-1 pl-lg-4 w-75">
             <form>
                <div className="row ">
                  <div className="col-lg-6 d-flex align-items-center form-group">
                    <div className="dropdown bootstrap-select">
                   <VirtualizedSelect
                      name="nbhd-code"
                      placeholder="Select NBHD Code"
                      filterOptions={filterOptions}
                      options={options}
                      onChange={(neighbourhoodCode) => this.setState({ neighbourhoodCode })}
                      value={this.state.neighbourhoodCode}
                     />                   
                    </div>
                  </div>
                  <div className="col-lg-4">
                  {this.state.neighbourhoodCode != null ? <NavLink to={{ pathname: `/view/${this.state.neighbourhoodCode.value}`, state:{neighbourhoodCode: this.state.neighbourhoodCode} }}> <button type="button" className="btn btn-primary btn-block rounded-xl h-100">Find </button></NavLink> : <button type="button" className="btn btn-primary btn-block rounded-xl h-100" disabled>Find</button> }
                  </div>
                </div>
            </form>
                   {this.state.submitted && this.renderResults()}

            </div>
          </div>
        </div>
         
        </div>

        <div className="container py-6 py-md-5 text-black z-index-20">
          <Jumbotron>
        <h5 className="display-5">Welcome!</h5>
        <p className="lead">MECKLENBURG COUNTY NEIGHBORHOOD REVIEW APP</p>
        <hr className="my-2" />
        <p>This online search tool allows you to look up key information about real estate properties in Mecklenburg County, North Carolina as recorded by the County Assessor’s Office. Information about property values and sales can all be found on the detail page of a chosen neighborhood. </p>
      </Jumbotron>
      </div>
     </div>
      <div className="container py-6 py-md-5 text-black z-index-20">
             <Row>
                  <Col sm="4">
                    <Card body>
                      <CardTitle>Property Record Card</CardTitle>
                      <CardText>This will take you to Mecklenburg's PRC site where you can view entire parcel and property details in a single place. You can search by filters, map selection or both.</CardText>
                      <a href="https://property.spatialest.com/nc/mecklenburg/#/" class="btn btn-secondary" role="button">Go to PRC</a>
                    </Card>
                  </Col>
                  <Col sm="4">
                    <Card body>
                      <CardTitle>Community</CardTitle>
                      <CardText>This will take you to Mecklenburg's Community site, a central data hub of map-based dashboard of information where you can visualize and analyze.</CardText>
                      <a href="https://community.spatialest.com/nc/mecklenburg/#/Area-Overview/map/35.265942,-80.842644,10" class="btn btn-secondary" role="button">Go to Community</a>
                    </Card>
                  </Col>
                   <Col sm="4">
                    <Card body>
                      <CardTitle>Comper</CardTitle>
                      <CardText>This will take you to Mecklenburg's Comper site where you can quickly and easily select, review, validate and share comparable sales on a map.</CardText>
                      <a href="https://nc-mecklenburg-citizen.comper.info/template.aspx" class="btn btn-secondary" role="button">Go to Comper</a>
                    </Card>
                  </Col>
                </Row>
    </div>
       <div className="p-3 my-2 rounded bg-docs-transparent-grid">
      
        <Button color="primary" onClick={this.toggle}>Need help?</Button>
        <Toast isOpen={this.state.show}>
          <ToastHeader toggle={this.toggle}>
            What to do?
          </ToastHeader>
          <ToastBody>
           {text2}
          </ToastBody>
        </Toast>
      </div>
    </CSSTransitionGroup>
  )
}
}

export default withRouter(Home);
