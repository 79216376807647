import React, {Component} from 'react'
import $ from 'jquery'

$.DataTable = require('datatables.net');
const jszip = require('jszip');
$.DataTable = require( 'datatables.net-select' );
window.JSZip = jszip;

class DetailedQualifiedSalesTable extends Component {

    constructor(props){
        super(props);
        this.state = {
            selected: [],  
        };

        this.setupTable = this.setupTable.bind(this);
        this.rowSelection = this.rowSelection.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);

    }
    componentDidMount() {
       this.setupTable(this.props.data);
    }

    rowSelection(parcelID){

        // this.setState({selected: parcelID});
       this.props.handleRowSelection(parcelID);
    }

    setupTable(data) {
        this.$el = $(this.el);
        var myThis = this;

        //Save datatable reference so we can attach events to the table
        const table = this.$el.DataTable(
            {
                bInfo : false,
                sort: 'enable',
                paging: false,
                select: true,
                'sDom': 't',
                scrollY: "300px",
                scrollX: "100%",
                select: {
                    style: 'single'
                },
                data: data,
                "oLanguage": {
                    "sSearch": "Search by Parcel ID or Address"
                },
                buttons: [{
                    extend: 'excel',
                    exportOptions: {
                        columns: '1, 2, 3'
                    },

                }],
                columns: [
                    {
                        "title": "Parcel ID",
                        mData: "ParcelID"
                    },
                    {
                        "title": "Address",
                        mData: "BillingAddress"
                    },
                      {
                        "title": "Multi",
                        mData: "multibuilding",
                        visible: false
                    },
                    {
                        "title": "Sale Date",
                        mData: "SaleDate",
                        render: function (data, type, row) {
                            let dateSplit = moment(data).format("MM-DD-YYYY").split('-');
                            return type === "display" || type === "filter" ?
                                dateSplit[0] + '-' + dateSplit[1] + '-' + dateSplit[2] :
                                data;
                        }
                    },
                    {
                        "title": "Sale ($)",
                        mData: "SalePrice",
                        render: $.fn.dataTable.render.number(',', '', 0)
                    },
                    {
                        "title": "Land ($)",
                        mData: "TotalLandValue",
                        render: $.fn.dataTable.render.number(',', '', 0)
                    },
                    {
                        "title": "Building ($)",
                        mData: "TotalBuildingValue",
                        render: $.fn.dataTable.render.number(',', '', 0)
                    },
                    {
                        "title": "Features ($)",
                        mData: "TotalYardItemValue",
                        render: $.fn.dataTable.render.number(',', '', 0)
                    },
                    {
                        "title": "Total ($)",
                        mData: "TotalValue",
                        render: $.fn.dataTable.render.number(',', '', 0)
                    },
                    {
                        "title": "Finished Area",
                        mData: "finishedarea",
                        render: $.fn.dataTable.render.number(',')
                    },
                    {
                        "title": "Land Use Code",
                        mData: "LandUseCode"
                    },
                    {
                        "title": "Built (Actual)",
                        mData: "yearbuilt"
                    },
                    {
                        "title": "Built (Effective)",
                        mData: "effectiveyearbuilt"
                    },
                    {
                        "title": "Grade",
                        mData: "grade"
                    },
                    {
                        "title": "Sold as Vacant?",
                        mData: "SoldasVacantFlag"
                    },
                    {
                        "title": "Ratios",
                        mData: "ratios",
                        render: $.fn.dataTable.render.number( ',', '.', 3 )
                    }
                ],
                // "createdRow": function (row, data, dataIndex) {
                //     if (data["multibuilding"] == "1") {
                //         $(row).css('background-color', '#fff067');
                //     }
                    
                // }

            }
        );

        function setID (e, dt, type){
                var id = table.row({ selected: true}).data().ParcelID;
                myThis.rowSelection(id);
        }
           
        //On row select do some function
        table.on('select',setID)

    }

    
        
    reloadTable(data) {
        this.$el = $(this.el);
        const table = this.$el.DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();


    }

    destroyTable() {
        this.$el = $(this.el);
        this.$el
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(prevProps) {
        this.reloadTable(this.props.data);
    }

    componentWillUnmount() {
        this.destroyTable();
    }

    shouldComponentUpdate() {
        return true;
    }


    static download_csv(csv, filename) {
        let csvFile;
        let downloadLink;

        // CSV FILE
        csvFile = new Blob([csv], {type: "text/csv"});

        // Download link
        downloadLink = document.createElement("a");

        // File name
        downloadLink.download = filename;

        // We have to create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);

        // Make sure that the link is not displayed
        downloadLink.style.display = "none";

        // Add the link to your DOM
        document.body.appendChild(downloadLink);

        // Lanzamos
        downloadLink.click();
    }

    exportThis() {
        const path = window.location.pathname;
        const selectedNeighborhoodCode = path.slice(path.lastIndexOf('/') + 1);
        let filename = "Qualified_Sales_" + selectedNeighborhoodCode + ".csv";
        let csv = [];
        let header = [
            "ParcelID",
            "Billing Address",
            "Sale Date",
            "Sale Price",
            "Land Value",
            "Building Value",
            "Features",
            "Total Value",
            "Finished Area",
            "Land Use Code",
            "Actual Year Built",
            "Effective Year Built",
            "Grade",
            "Sold as Vacant",
            "Ratios"
        ];

        let table = this.el;
        let rows = table.querySelectorAll("tr");

        csv.push(header.join(","));

        for (let i = 0; i < rows.length; i++) {
            let row = [], cols = rows[i].querySelectorAll("td");
            for (let j = 0; j < cols.length; j++) {
                row.push('"' + cols[j].innerText + '"');
            }
            csv.push(row.join(","));
        }
        // Download CSV
        let csvFile;
        let downloadLink;

        let bob = csv.join("\n");

        // CSV FILE
        csvFile = new Blob([bob], {type: "text/csv"});


        // Download link
        downloadLink = document.createElement("a");

        // File name
        downloadLink.download = filename;

        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
            window.navigator.msSaveOrOpenBlob(csvFile, filename);
        } else {
            // We have to create a link to the file
            downloadLink.href = window.URL.createObjectURL(csvFile);
        }

        // Make sure that the link is not displayed
        downloadLink.style.display = "none";

        // Add the link to your DOM
        document.body.appendChild(downloadLink);

        // Lanzamos
        downloadLink.click();
    }

   onKeyUp(event){
        this.$el = $(this.el);
        const table = this.$el.DataTable();
        table.search(event.target.value).draw();
    } 

    warningMessage = "Lines that are shaded indicate that there is more than one building or improvement type on the property at the time of the sale";
    infoMessage = "Clicking on a row will highlight the corresponding parcel in the map.";

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <input className="form-control" placeholder="Search by Parcel ID or Address" style={{width: '300px'}} type="text" onKeyUp={ this.onKeyUp } />
                    </div>

                    <div className="col-md-6 order-md-last order-sm-first mb-2">
                        <div className="float-sm-right">
                            <button type="button" className="btn btn-primary" onClick={ () => this.exportThis() }>Download CSV file</button>
                        </div>
                    </div>
                </div>

                    <table className="table table-striped table-bordered dt-responsive nowrap"  width="100%"
                           ref={el => this.el = el}>
                    </table>
            </div>
        )
    }
}

export default DetailedQualifiedSalesTable;