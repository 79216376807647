import $ from 'jquery'
import React, {Component} from 'react'

$.DataTable = require('datatables.net');

const jszip = require('jszip');
$.DataTable = require( 'datatables.net-select' );
window.JSZip = jszip;

class NeighborhoodValuesTable extends Component {

    constructor(props){
        super(props);

        this.onKeyUp = this.onKeyUp.bind(this);
    }

    componentDidMount() {
        this.setupTable(this.props.data);
    }

    setupTable(data) {

        this.$el = $(this.el);
        this.$el.DataTable(
            {
                bInfo : false,
                sort: 'enable',
                legend: {position: "bottom"},
                paging: false,
                select: true,
                'sDom': 't',
                scrollY: "300px",
                scrollX: "100%",
                select: {
                    style: 'single'
                },
               
                data: data,
                "oLanguage": {
                    "sSearch": "Search ID or Address"
                },
               
                
                columns: [
                    {
                        "title": "Parcel ID",
                         mData: "ParcelID"
                    },
                    {
                        "title": "Land ($)",
                        mData: "TotalLandValue",
                        render: $.fn.dataTable.render.number(',', '', 0, '$')
                    },
                    {
                        "title": "Building ($)",
                        mData: "TotalBuildingValue",
                        render: $.fn.dataTable.render.number(',', '', 0, '$')
                    },
                    {
                        "title": "Features",
                        mData: "TotalYardItemValue",
                        render: $.fn.dataTable.render.number(',', '', 0, '$')
                    },
                    {
                        "title": "Total ($)",
                        mData: "TotalValue",
                        render: $.fn.dataTable.render.number(',', '', 0, '$')
                    },
                    {
                        "title": "Finished Area",
                        mData: "finishedarea",
                        render: $.fn.dataTable.render.number(',')
                    },
                    {
                        "title": "Effective Year Built",
                        mData: "effectiveyearbuilt"
                    },
                    {
                        "title": "Grade",
                        mData: "grade"
                    }
                ],
               
            }
        );
    }

    reloadTable(data) {

        this.$el = $(this.el);
        const table = this.$el.DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
        table.columns.adjust().draw();
 

    }

    destroyTable() {
        this.$el = $(this.el);
        this.$el
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(prevProps) {
        this.reloadTable(this.props.data);
    }

    componentWillUnmount() {
        this.destroyTable();
    }

    shouldComponentUpdate() {
        return true;
    }


    static download_csv(csv, filename) {
        let csvFile;
        let downloadLink;

        // CSV FILE
        csvFile = new Blob([csv], {type: "text/csv"});

        // Download link
        downloadLink = document.createElement("a");

        // File name
        downloadLink.download = filename;

        // We have to create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);

        // Make sure that the link is not displayed
        downloadLink.style.display = "none";

        // Add the link to your DOM
        document.body.appendChild(downloadLink);

        // Lanzamos
        downloadLink.click();
    }

    exportThis() {
        const path = window.location.pathname;
        const selectedNeighborhoodCode = path.slice(path.lastIndexOf('/') + 1);
        let filename = "Neighborhood_Values_" + selectedNeighborhoodCode + ".csv";
        let csv = [];
        let header = [
            "ParcelID",
            "Land Value",
            "Building Value",
            "Features",
            "Total Value",
            "Finished Area",
            "Effective Year Built",
            "Grade"
        ];
        let table = this.el;
        let rows = table.querySelectorAll("tr");

        csv.push(header.join(","));

        for (let i = 0; i < rows.length; i++) {
            let row = [], cols = rows[i].querySelectorAll("td");
            for (let j = 0; j < cols.length; j++) {
                row.push('"' + cols[j].innerText + '"');
            }
            csv.push(row.join(","));
        }
        // Download CSV
        let csvFile;
        let downloadLink;

        let bob = csv.join("\n");

        // CSV FILE
        csvFile = new Blob([bob], {type: "text/csv"});


        // Download link
        downloadLink = document.createElement("a");

        // File name
        downloadLink.download = filename;

        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
            window.navigator.msSaveOrOpenBlob(csvFile, filename);
        } else {
            // We have to create a link to the file
            downloadLink.href = window.URL.createObjectURL(csvFile);
        }

        // Make sure that the link is not displayed
        downloadLink.style.display = "none";

        // Add the link to your DOM
        document.body.appendChild(downloadLink);

        // Lanzamos
        downloadLink.click();
    } 

     onKeyUp(event){
        this.$el = $(this.el);
        const table = this.$el.DataTable();
        table.search(event.target.value).draw();
    }

    render() {
        return (
         <div>
                <div class="row">
                    <div className="col-md-6 mb-2">
                        <input className="form-control" placeholder="Search by Parcel ID or Address" style={{width: '300px'}} type="text" onKeyUp={ this.onKeyUp } />
                    </div>

                    <div className="col-md-6 order-md-last order-sm-first mb-2">
                        <div className="float-sm-right">
                            <button type="button" className="btn btn-primary" onClick={ () => this.exportThis() }>Download CSV file</button>
                        </div>
                    </div>
                </div>

                    <table className="table table-striped table-bordered dt-responsive nowrap" width="100%"
                           ref={el => this.el = el}>
                    </table>
            </div>
        )
    }
}

export default NeighborhoodValuesTable;