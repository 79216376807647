import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import Map from  './MapComponent'
import Dropdown from  '../reusable/reusable/dropdown'
import { Chart } from 'react-google-charts';
import DetailedQualifiedSalesTable from '../DetailedQualifiedSalesTable';
import NeighborhoodValuesTable from '../NeighborhoodValuesTable';
import Select from 'react-select';
import {neighbourhoodCodes} from '../neighbourhoodCodes';
import { 
            UncontrolledTooltip, Container, Row, Col, Button, Badge, Collapse, Navbar, NavbarToggler, NavbarBrand, 
            Nav, NavItem, NavLink, Table, Card, CardHeader, CardBody, TabContent, TabPane, Alert
        }  from 'reactstrap';
import classnames from 'classnames';

import VirtualizedSelect from 'react-virtualized-select';
import createFilterOptions from '../filter';
import 'react-select/dist/react-select.css';

import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import config from '../config/index.js';

const options = neighbourhoodCodes;
const filterOptions = createFilterOptions({options});


class SearchResults extends Component {

    constructor(props){
        super(props);

        this.state = {
            qualifiedSales: [],
            allSales: [],
            chartData: [],
            chartOptions: {
                curveType: 'function',
                legend: { position: 'bottom' }
            },
            collapsed: true,
            selectedParcelID: null,
            dropdownOpen: false,
            activeTab: '1',
               loading: false,
        };

        this.toggle = this.toggle.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleRowSelection = this.handleRowSelection.bind(this);
        this.onNeighborhoodSelect = this.onNeighborhoodSelect.bind(this);

                this.dropdown = React.createRef();

    }

    componentWillMount() {
        const code = this.props.match.params.neighbourhoodCode;
        this.loadData(code);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.match.params.neighbourhoodCode !== this.props.match.params.neighbourhoodCode){
            this.loadData(nextProps.match.params.neighbourhoodCode);
        }
    }

     loadData(code){
        if(code && this.validCode(code)){
           this.setState({ loading: true });
           this.getNeighborhoodData(code);
           this.getChartData(code);
        }
        else{
            this.invalidCode();
        }
    }

    //TODO:: handle this better 
    invalidCode(){
        alert("Invalid Code!");
    }

    //Check if neighborhood code is valid
    validCode(code){
        return neighbourhoodCodes.filter(item => (item.value === code)).length > 0;
    }


    handleRowSelection(parcelID){
     this.setState({selectedParcelID: parcelID});
    }

    getNeighborhoodData(code){
       delete axios.defaults.headers.common["X-Requested-With"];
       const url = `${config.baseUrl}/api/neighborhood/${code}`;

        axios.get(url).then(
            (response) => {
                this.setState({
                    qualifiedSales: response.data.qualifiedsales,
                    allSales: response.data.neighborhoodsales,
                })
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getChartData(code){
        delete axios.defaults.headers.common["X-Requested-With"];
        const url = `${config.baseUrl}/api/chart/${code}`;

        axios.get(url).then(
            (response) => {
                this.setState({
                    chartData: response.data,
                    loading: false,
                });
            },
            (err) => {
                console.log(err);
                 this.setState({
                    loading: false,
                });
            }
        );
    }

    reloadTableData(data) {
        let table = $('.data-table-wrapper')
            .find('table')
            .DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }
 
  

   
    static getMinSalePrice(data) {
        return Math.min(...data.map(({SalePrice}) => SalePrice)).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0})
    };

    static getMaxSalePrice(data) {
        return Math.max(...data.map(({SalePrice}) => SalePrice)).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0})
    };

    static getMedianSalesPrice(data) {
        let SalePrice = data.map(({SalePrice}) => SalePrice).filter(r => r);
        SalePrice.sort((a,b) => a - b);
        if(!SalePrice.length) {
            return 0;
        }
        if(SalePrice.length % 2 === 0) {
            let underamount = Number.parseInt(SalePrice[((data.length) / 2)-1]);
            let overamount = Number.parseInt((SalePrice[data.length /2]));

            return ((underamount + overamount) / 2).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0});
        }
        return Number.parseInt(SalePrice[(data.length/2)-0.5]).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0});
    };

    getDataFormattedForChart (data) {
        const countyAverage =[
            ['2012 Q1', 45000], ['2012 Q2', 75000], ['2012 Q3', 88500], ['2012 Q4', 80500],
            ['2013 Q1', 79000], ['2013 Q2', 123500],['2013 Q3', 120000],['2013 Q4', 114000],
            ['2014 Q1', 110000],['2014 Q2', 137000],['2014 Q3', 145000], ['2014 Q4', 138000],
            ['2015 Q1', 143000], ['2015 Q2', 222000], ['2015 Q3', 200000], ['2015 Q4', 188500],
            ['2016 Q1', 186500], ['2016 Q2', 211250], ['2016 Q3', 210000], ['2016 Q4', 214000],
            ['2017 Q1',	214000], ['2017 Q2', 236000], ['2017 Q3', 225000], ['2017 Q4', 239000],
            ['2018 Q1',	238000], ['2018 Q2', 250500], ['2018 Q3', 241000], ['2018 Q4', 241000]
        ];

        let chartData = data.map(d => Array.from(Object.values(d)));
        let chartHeader = ["Sales Date","Neighborhood Average","County Average"];
        for (let i=0; i < chartData.length; i++) {
            if (chartData[i][1] === 0) {
                chartData[i][1] = countyAverage[i][1];
            }
            chartData[i].push(countyAverage[i][1]);
        }
        chartData.unshift(chartHeader);
        return chartData;
    }

    toggleDropdown(event) {
        if(this.dropdown.current){
            this.dropdown.current.toggle(event);
        }
    }

    onNeighborhoodSelect(selection){
        this.toggleDropdown();
        const code = selection.value.toUpperCase().trim();

        if (code && this.validCode(code)){
            this.props.history.push('/view/' + code);
        }
        else{
            this.invalidCode();
        }
    }

    getMapData(){
        const { allSales } = this.state;

        //TODO:: Does this need merged with qualified sales?
        return allSales;
    }

    
 // toggle() {
 //    this.setState({
 //      dropdownOpen: !this.state.dropdownOpen
 //    });
 //  }

   toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

    render() {
      const { chartData, selectedOption, qualifiedSales, allSales } = this.state;
      //To display in title
        let code = this.props.match.params.neighbourhoodCode;

        //If code in url is invalid replace it
        if(!this.validCode(code))
            code = "Invalid Code";
        return (
         <div className="container-fluid">
                     <div className="row">
                        <nav className="col-md-12 navbar navbar-expand-sm bg-navy py-0">
                        <a href="/"><img className="logo" alt="Logo" src="https://assets.spatialest.com/clients/nc/mecklenburg/mecklenburg.png" /><h1 className="county_banner valign">Mecklenburg County Neighborhood Review</h1></a>
                         <button className="ml-auto btn btn-outline-light text-light" onClick={this.toggleDropdown } type="button">
                            <span className="fa fa-search"></span>
                        </button>
                     </nav>
                     </div>
                       <Dropdown ref={ this.dropdown }>
                    <div className="row">
                            <div className="col">  
                                <div className="dropdown bootstrap-select float-right">
                                    <VirtualizedSelect
                                      name="nbhd-code"
                                      placeholder="Select NBHD Code"
                                      filterOptions={filterOptions}
                                      options={options}
                                      onChange={this.onNeighborhoodSelect}
                                      value={this.state.neighbourhoodCode}
                                    />                 
                                 </div>
                            </div>
                        </div>
                      </Dropdown>
                     <div className="row">
                    <div className="col-md-4"> 
                        <h4 className="text-center font-weight-bold mt-3">
                                Qualified Sales by Neighborhood: 
                                <span> { code }</span>
                            </h4>

                            <h5 className="text-center">
                                Total Sales: {qualifiedSales.length}
                            </h5>

                            <div className="mb-4" style={{height: '301px'}}>
                                { 
                                    this.state.loading  ? 
                                        <div className="d-flex justify-content-center"><div className="spinner-border text-primary "></div></div> 
                                    :
                                        this.state.chartData.length > 0 ?
                                            <Chart
                                                chartType="LineChart"
                                                curveType="function"
                                                width="105%"
                                                height="301px"
                                                data={this.getDataFormattedForChart(chartData)}
                                                options={this.state.chartOptions} />
                                        :
                                            <Alert className="ml-3" color="info">No data to display</Alert>
                                }
                        </div>
                            <Row>
                                <div className="col col-md-4 pl-5">
                                    <h4><Badge color="light"><span href="#" id="MinUncontrolledTooltipExample">Min</span></Badge>{qualifiedSales.length ? <td>{SearchResults.getMinSalePrice(qualifiedSales)}</td> : <td></td>}</h4>
                                </div>
                                 <UncontrolledTooltip placement="top" target="MinUncontrolledTooltipExample">
                                     Minimim Sale Price
                                  </UncontrolledTooltip>
                                <div className="col col-md-4 pl-5">
                                    <h4><Badge color="light"><span href="#" id="MedUncontrolledTooltipExample">Med</span></Badge>{qualifiedSales.length ? <td>{SearchResults.getMedianSalesPrice(qualifiedSales)}</td> : <td></td>}</h4>
                                </div>
                                 <UncontrolledTooltip placement="top" target="MedUncontrolledTooltipExample">
                                     Medium Sale Price
                                  </UncontrolledTooltip>
                                <div className="col col-md-4 pl-5">
                                  <h4><Badge color="light"><span href="#" id="MaxUncontrolledTooltipExample">Max</span></Badge>{qualifiedSales.length ? <td>{SearchResults.getMaxSalePrice(qualifiedSales)}</td> : <td></td>}</h4>
                                </div>
                                 <UncontrolledTooltip placement="top" target="MaxUncontrolledTooltipExample">
                                     Maximum Sale Price
                                  </UncontrolledTooltip>
                            </Row>
                          
                        </div>
                    <div className="col-md-7 offset-md-1 mb-4 p-md-0 p-5">
                            <Map
                                ref={(map) => this.map = map}
                                selected={this.state.selectedParcelID}
                                data={ this.getMapData() }
                            />
                        </div>
                    </div>
              
                        <div className="pl-2 pr-2">                      
                          <Nav tabs>
                              <NavItem>  
                              <span href="#" id="TableUncontrolledTooltipExample"> 
                                <NavLink
                                  className={classnames({ active: this.state.activeTab === '1' })}
                                  onClick={() => { this.toggle('1'); }}
                                >
                                 Detailed Qualified Sales 
                                </NavLink></span>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({ active: this.state.activeTab === '2' })}
                                  onClick={() => { this.toggle('2'); }}
                                >
                                  All Neighborhood Values
                                </NavLink>
                              </NavItem>
                        </Nav>
                         <TabContent activeTab={this.state.activeTab}>
                              <TabPane tabId="1">
                                   <DetailedQualifiedSalesTable data={ this.state.qualifiedSales } handleRowSelection={this.handleRowSelection}/> 
                              </TabPane>
                              <TabPane tabId="2" >
                                    <NeighborhoodValuesTable data={ this.state.allSales } />
                              </TabPane>
                        </TabContent>
                          <UncontrolledTooltip placement="top" target="TableUncontrolledTooltipExample">
                             Click on a row to highlight in the map.
                           </UncontrolledTooltip>
                        </div>
                        <footer className="navbar footer container-fluid">
                          <div className="col-md-12 text-right bg-navy">
                            <span>Powered by </span>
                            <a href="https://spatialest.com"><img className="logo-spatialest" src="../images/spatialest-yw-logo.png"></img></a>
                          </div>
                      </footer>
               </div>

        )
    }
}

export default withRouter(SearchResults);