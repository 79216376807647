import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Homepage from './Homepage';
import SearchResults from './SearchResults';


export default class App extends Component {
   render() {
    return (
      <BrowserRouter>
         <Switch>
           <Route exact path="/" component={Homepage} />
           <Route path="/view/:neighbourhoodCode?" component={()=><SearchResults/>} />
         </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('app'));
