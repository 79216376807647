import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import './dropdown.scss';

class Dropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            closing: false,
            opening: false,
            complete: true,
            visible: this.props.visible
        };

        this.toggle = this.toggle.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.shouldClose = this.shouldClose.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.shouldClose, false);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.shouldClose, false);
    }

    componentDidUpdate(prevProps, prevState) {
        this.setDropdownHeight();

        if (this.state.closing || this.state.opening)
            this.animate(this.state.visible);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.visible !== nextProps.visible)
            this.setState({visible: nextProps.visible});
    }

    isInViewport(offset = 0) {
        const node = this.node;

        if (!node) return false;

        const top = node.getBoundingClientRect().top;
        return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
    }

    shouldClose(){
        const dropdown = this.menu;

        if(!this.isInViewport(dropdown.clientHeight))
            this.close();
    }

    setDropdownHeight(){

        const dropdown = this.menu;
        let height = 0;

        //Set closing size before animation class is added
        if (this.state.closing) {
            height = (dropdown ? dropdown.scrollHeight : 0) + "px";
        }

        //Set height to auto once open to retain height
        if (this.state.complete && this.state.visible){
            height = "auto";
        }

        if(dropdown) {
            dropdown.style.height = height;
        }

    }

    toggle(event) {
        const state = !this.state.visible;

        //Only animate if previous animation is complete
        if (this.state.complete) {
            if (state)
                this.open(event);
            else
                this.close(event);
        }
    }

    open(event) {
        //Only animate if previous animation is complete
        this.setState({
            closing: false,
            opening: true,
            visible: true,
            complete: false
        });
    }

    close(event) {
        //Only animate if previous animation is complete
        if (this.state.complete && this.state.visible) {
            this.setState({
                closing: true,
                opening: false,
                visible: false,
                complete: false
            });
        }
    }

    animate(visible) {
        const self = this;
        const node = this.node;
        const dropdown = this.menu;
        const timeout = visible ? 600 : 800;

        if (dropdown) {

            //Add animate to height after class has been added
            if (this.state.opening) {
                const scrollHeight = dropdown ? dropdown.scrollHeight : 0;
                dropdown.style.height = scrollHeight + "px";
            }

            //Add animate class to close the menu as initial height is added on render
            if (this.state.closing) {
                setTimeout(() => {
                    node.classList.add("is-closing");
                    //dropdown.style.transitionDuration = "500ms";
                });
            }

            setTimeout(() => {

                self.setState({
                    closing: false,
                    opening: false,
                    complete: true,
                    visible: visible
                });

            }, timeout);
        }
    }

    render() {
        return (
            <div 
                className={ 
                    "dd-dropdown-wrapper " +
                    (this.state.opening ? "is-opening " : "") +
                    //(this.state.closing ? "is-closing " : "") +
                    (this.state.complete ? (this.state.visible ? "open" : "closed") : "") }
                ref={ node => this.node = node }>

                <div className="dd-dropdown-menu"
                     ref={ menu => this.menu = menu }>
                    <div className="dd-dropdown">
                        <div className="dd-container">
                            {this.props.children}
                        </div>
                    </div>
                </div>
                <div className="dd-dropdown-backdrop" onClick={ this.close }></div>
            </div>
        );
    }
};

Dropdown.defaultProps = {
    visible: false
};

export default Dropdown;
