import $ from 'jquery'
import React, { Component } from 'react'
import Map from 'ol/Map';
import View from 'ol/View';
import ZoomSlider from 'ol/control/ZoomSlider';
import TileLayer from 'ol/layer/Tile';
import { createStringXY } from 'ol/coordinate.js'
import MousePosition from 'ol/control/MousePosition'
import Image from 'ol/layer/Image'
import ImageWMS from 'ol/source/ImageWMS'
import OSM from 'ol/source/OSM'
import TileWMS from 'ol/source/TileWMS'
import { fromLonLat } from 'ol/proj.js'
import Vector from 'ol/layer/Vector'
import SourceVector from 'ol/source/Vector'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'
import MultiPolygon from 'ol/geom/MultiPolygon'
import GeometryCollection from 'ol/geom/GeometryCollection'
import Point from 'ol/geom/Point'
import Feature from 'ol/Feature'
import Overlay from 'ol/Overlay'
import OLWKT from 'ol/format/WKT'

let mousePositionControl = new MousePosition({
    className: 'custom-mouse-positon',
    target: document.getElementById('location'),
    coordinateFormat: createStringXY(5),
    undefinedHTML: '&nbsp;'
});

let tableData = null;

const tiled = new Image({
    source: new ImageWMS({
        ratio: 1,
        url: '//geo2.spatialest.com/geoserver/wms',
        params: {
            "LAYERS": 'mecklenburg:meck_parcels'
        }
    })
});

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});

class MapComponent extends Component {
    constructor(props){
        super(props);

        this.state = {
            initComplete: false
        };
    }

    componentWillReceiveProps(nextProps) {
        //TODO:: Check if new props are different
        if (this.state.initComplete && this.map){

            if(nextProps.data.length !== this.props.data.length) {
                this.load_data(nextProps.data);
            }

            //TODO:: IF a row is selected and the popup disappears the same row cannot be clicked again this needs addressed 
            //Removeing the if statement will not fix the issue but instead on any post back the popup will appear
            if(nextProps.selected !== this.props.selected) {
                this.highlightFeature(this.findFeature(nextProps.selected));
            }

        }
    }

    componentDidMount() {
        //TODO:: Use react references
        let container = document.getElementById('popup');
        let closer = document.getElementById('popup-closer');

        /**
         * Create an overlay to anchor the popup to the map.
         */

        this.overlay = new Overlay({
            element: container
        });

        this.map = new Map({
            target: 'map',
            layers: [
                new TileLayer({
                    source: new OSM()
                }),
                new TileLayer({
                    source: new TileWMS({
                        url: '//geo2.spatialest.com/geoserver/wms',
                        params: {
                            'LAYERS': 'mecklenburg:Meck_County_Boundary',
                            'TILED': true,
                            SRS: 'EPSG:3857'
                        },
                        serverType: 'geoserver',
                        crossOrigin: 'anonymous',
                        transition: 0
                    })
                }),
            ],
            overlays: [this.overlay],
            view: new View({
                center: fromLonLat([-80.848087, 35.262272]),
                zoom: 10
            })
        });

        this.map.addControl(new ZoomSlider());

        /**
         * Add a click handler to hide the popup.
         * IF POPUP DISAPPEARS ON HOVER WHY IS THERE A CLOSE BUTTON
         */
        closer.onclick = function() {
            this.overlay.setPosition(undefined);
            closer.blur();
            return false;
        };

        this.init_layers();
        this.init_events();
        this.load_data();

        this.setState({
            initComplete: true
        });
    };

    init_events(){
        this.map.on('pointermove', (event) => {
            if (event.dragging) return;

            let feature = this.map.forEachFeatureAtPixel(
                event.pixel,
                function (ft, l) {
                    return ft;
                }
            );

            this.highlightFeature(feature, event);

        });
    }

    init_layers(){
        this.vectorLayer = new Vector({
            source: new SourceVector(),
            map: this.map,
            visible: true,
            zIndex: 500,
            style: new Style({
                stroke: new Stroke({
                    color: '#F8987C',
                    width: 2
                }),
                fill: new Fill({
                    color: 'rgba(248,248,121,0.8)'
                })
            })
        });
    }

    load_data(data) {

        data = data || this.props.data;

        this.clearPlotLayer();

        if (data && data.length != 0) {
            //loads lists of features
            data.forEach((item) => {
                this.load_item(item);
            });

            const source = this.vectorLayer.getSource();

            if (source.getFeatures().length > 0) {
                //zoom to selection if features exist
                const extent = source.getExtent();
                this.map.getView().fit(extent, this.map.getSize());
            }
        }
    }

    load_item(item) {
        var plot = null, title = null, pointGeom = null, polygon = null;
        var geomCollection = [];
        var format = new OLWKT();

        if (!item.geom)
            return;

        //feature geometry
        if (item.geom) {
            polygon = format.readGeometry(item.geom, {dataProjection: 'EPSG:3857', featureProjection: 'EPSG:3857'})
        }

        const extent = polygon.getInteriorPoints();
        const point = new Point([extent.flatCoordinates[0], extent.flatCoordinates[1]]); 
        
        //Push geom to collection - MUST be in this order for styles to work
        geomCollection.push(point);
        geomCollection.push(polygon);

        var plot = new Feature({
            geometry: new GeometryCollection(geomCollection),
            name:item.ParcelID,
            data: item
        });
        
        plot.setStyle(this.getStyle(plot));
        this.vectorLayer.getSource().addFeature(plot);
    }

    clearPlotLayer() {
        this.vectorLayer.getSource().clear();
    }

    clearTheHoverStyle = () => {
        this.vectorLayer.getSource().getFeatures().forEach( (feature) => {
            feature.setStyle(this.getStyle(feature));
        });
    };

    highlightFeature(feature, event){
        this.clearTheHoverStyle();

        if (feature) {
            let content = document.getElementById('popup-content');
            let parcelData = feature.get('data');
            //If no event passed in used feature center point
            let mouseCoordinates = event ? event.coordinate : feature.getGeometry().getGeometries()[0].getCoordinates();
            if (parcelData != null) {
               let details = "<ul>";

               details += `
                    <li>Parcel ID: ${parcelData.ParcelID}</li>
                    <li>Billing Address: ${parcelData.BillingAddress}</li>
                    <li>Land Value: ${formatter.format(parcelData.TotalLandValue)}</li>
                    <li>Building Value: ${formatter.format(parcelData.TotalBuildingValue)}</li>
                    <li>Features: ${formatter.format(parcelData.TotalYardItemValue)}</li>
                    <li>Total Value: ${formatter.format(parcelData.TotalValue)}</li>
                    <li>Grade: ${parcelData.grade}</li>
                `;

                if(parcelData.yearbuilt)
                    details += `<li>Year Built: ${parcelData.yearbuilt}</li>`;
                
                if(parcelData.yearsold)
                    details += `<li>Year Sold: ${parcelData.yearsold}</li>`;
                
                details += "</ul>";
 
                content.innerHTML = details;
            } else {
               content.innerHTML = `<p>No Parcel Information</p>`;
            }
            this.overlay.setPosition(mouseCoordinates);
            feature.setStyle(this.getHoverStyle(feature));
        }
        if (!feature) this.overlay.setPosition(feature);
    }

    getStyle(feature){
        const geometries = feature.getGeometry().getGeometries();
        const plotpoint = geometries[0];
        const plotgeom = geometries[1];
        const PID = feature.values_.name;

        let colourOverlay = 'rgba(247, 253, 38, 0.5)';
        //Get data for feature
        let parcelData = feature.get('data');

        if (parcelData) {
            if (parcelData.yearsold == "2021") colourOverlay = 'rgba(255,127,80, 1)';
            if (parcelData.yearsold == "2022") colourOverlay = 'rgba(80, 255, 215, 0.8)';
        };

        const point = new Style({
            geometry: plotpoint,
            zIndex: 10
        });
        const geom = new Style({
            geometry: plotgeom,
            fill: new Fill({
                color: colourOverlay
            }),
            stroke: new Stroke({
                color: 'rgba(149, 111, 111, 0.5)',
                width: 1
            }),
            zIndex: 10
        });
        return [point, geom];
    }

    getHoverStyle(feature){
        const geometries = feature.getGeometry().getGeometries();
        const plotpoint = geometries[0];
        const plotgeom = geometries[1];

        const point = new Style({
            geometry: plotpoint,
            zIndex: 10
        });
        const geom = new Style({
            geometry: plotgeom,
            fill: new Fill({
                color: 'rgba(120, 16, 243, 1)'
            }),
            stroke: new Stroke({
                color: 'rgba(33, 33, 33, 1)',
                width: 1
            }),
            zIndex: 10
        });
        return [point, geom];
    }

    findFeature(value, key = "name", clone) {
        var features = this.vectorLayer.getSource().getFeatures();

        var feature = features.find((feature) => {
            var keyvalue = feature.get(key);
            return value == keyvalue;
        });

        return feature && clone ? feature.clone() : feature;
    }

    render() {
        return (
            <div>
                <div className="column" id="map"> 
                <div id="legend" className="rounded"><b>Map Key</b><div className="small-font font-italic">Sold In</div>
                        <div><i class="fa fa-circle icon-background1" aria-hidden="true"></i> 2021</div>
                        <div><i class="fa fa-circle icon-background2" aria-hidden="true"></i> 2022</div>
                </div>
                </div>
                <div id="popup" className="ol-popup">
                    <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                    <div id="popup-content"></div>
                </div>
            </div>
        )
    };
}

MapComponent.defaultProps = {
    dataIDKey: "ParcelID"
};

export default MapComponent;